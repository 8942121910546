<template>
  <div class="cooperation">
    <Header></Header>
    <div class="banner img_content_pub">
      <img v-lazy="require('../assets/imgs/banner.png')" alt="" />
    </div>
    <div>
      <img
        style="width: 1200px"
        v-for="item in resourcesUrl"
        v-lazy="item.resourcesUrl"
        alt=""
      />
    </div>
    <div class="title">
      <img src="../assets/imgs/title_bg_left.png" alt="" />
      <span>招商意向</span>
      <img src="../assets/imgs/title_bg_right.png" alt="" />
    </div>
    <div class="form">
      <div class="form_content">
        <div class="form_title">*姓名</div>
        <div class="form_box">
          <input class="inp" type="text" v-model="form.name" placeholder="请输入姓名" />
        </div>
      </div>
      <div class="form_content">
        <div class="form_title">*电话</div>
        <div class="form_box">
          <input
            class="inp"
            maxlength="11"
            type="tel"
            v-model="form.phone"
            placeholder="请输入电话"
          />
        </div>
      </div>
      <div class="form_content">
        <div class="form_title">邮箱</div>
        <div class="form_box">
          <input
            class="inp"
            type="email"
            v-model="form.mailbox"
            placeholder="请输入邮箱"
          />
        </div>
      </div>
      <div class="form_content">
        <div class="form_title">区域</div>
        <div class="form_box">
          <input
            class="inp"
            type="text"
            v-model="form.address"
            placeholder="请输入区域"
          />
        </div>
      </div>
      <div class="form_content">
        <div class="form_title"></div>
        <div class="btn" @click="onClick">确认提交</div>
      </div>
    </div>
    <div
      class="message_box_content"
      :class="{ warning: warning, error: error, success: success }"
    >
      <div class="message_box">{{ message }}</div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import Footer from "../components/footer";
import Header from "../components/header";
import { addIntention, getLandingPage } from "../api/api";

export default {
  name: "Cooperation",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      active: 0,
      warning: false,
      error: false,
      success: false,
      message: "",
      form: {
        name: "",
        phone: "",
        mailbox: "",
        address: "",
      },
      resourcesUrl: "",
    };
  },
  mounted() {
    this.getLandingPage();
  },

  methods: {
    // 判断手机号是否合法
    isPhoneNumber(tel) {
      var reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/;
      return reg.test(tel);
    },

    getLandingPage() {
      getLandingPage().then((res) => {
        console.log(res);
        this.resourcesUrl = res.data;
      });
    },
    onClick() {
      console.log(this.form)
      console.log(this.isPhoneNumber(this.form.phone))
      if (this.form.name == "") {
        this.message = "请输入姓名！";
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 3000);
      } else if (this.form.phone == "") {
        this.message = "请输入电话！";
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 3000);
      } else if (!this.isPhoneNumber(this.form.phone)) {
        this.message = "电话输入有误！";
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 3000);
      } else {
        this.addIntention();
      }
    },
    onChange(index) {
      this.active = index;
    },
    addIntention() {
      var data = this.form;
      addIntention(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.message = "提交成功！";
          this.form = {
            name: "",
            phone: "",
            mailbox: "",
            address: "",
          };
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.cooperation {
  .title {
    margin: 40px 0;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 70px;
      height: 26px;
    }
    span {
      font-size: 36px;
      color: #d6ac7d;
      margin: 0 10px;
    }
  }
  .form {
    margin-bottom: 40px;
    .form_content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      .form_title {
        font-size: 20px;
        color: #999999;
        margin-right: 28px;
        width: 60px;
        text-align: right;
      }
      .form_box {
        width: 580px;
        height: 58px;
        border: 1px solid #d3d3d3;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        .svg-icon {
          width: 18px;
          height: 18px;
        }
        .inp {
          border: none;
          height: 20px;
          line-height: 20px;
          width: 550px;
          margin-left: 5px;
          background: none;
          outline: none;
          color: #333;
          font-size: 20px;
        }
        .inp::-webkit-input-placeholder {
          /* placeholder颜色  */
          color: #999999;
          /* placeholder字体大小  */
          font-size: 18px;
        }
      }
      .btn {
        width: 580px;
        height: 58px;
        background: #d6ac7d;
        text-align: center;
        line-height: 58px;
        cursor: pointer;
        font-size: 20px;
        color: #fff;
        // position: relative;
        // left: 30px;
      }
    }
  }
  .message_box_content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    .message_box {
      font-size: 16px;
      width: 500px;
      margin: auto;
      background: #fff;
      padding: 15px;
      border-radius: 3px;
      border: 1px solid;
    }
  }

  .message_box_content.warning {
    .message_box {
      color: #e6a23c;
      background: #fdf6ec;
      border-color: #f5dab1;
    }
    animation: lengthen 0.5s;
    -webkit-animation: lengthen 0.5s;
    animation-fill-mode: forwards;
  }
  .message_box_content.error {
    .message_box {
      color: #f56c6c;
      background: #fef0f0;
      border-color: #fbc4c4;
    }
    animation: lengthen 0.5s;
    -webkit-animation: lengthen 0.5s;
    animation-fill-mode: forwards;
  }
  .message_box_content.success {
    .message_box {
      color: #67c23a;
      background: #f0f9eb;
      border-color: #c2e7b0;
    }
    animation: lengthen 0.5s;
    -webkit-animation: lengthen 0.5s;
    animation-fill-mode: forwards;
  }
  @keyframes lengthen {
    from {
      top: 0;
    }
    to {
      top: 20%;
    }
  }

  @-webkit-keyframes lengthen /* Safari 与 Chrome */ {
    from {
      top: 0;
    }
    to {
      top: 20%;
    }
  }
}
</style>
