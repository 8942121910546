<template>
  <div class="about">
    <Header @link-jump3="jumpA"></Header>
    <div class="banner img_content_pub">
      <img v-lazy="require('../assets/imgs/banner.png')" alt="" />
    </div>
    <div id="groupIntroduction">
      <div class="title">
        <img src="../assets/imgs/title_bg_left.png" alt="" />
        <span>集团介绍</span>
        <img src="../assets/imgs/title_bg_right.png" alt="" />
      </div>
      <div class="introduce">
        <img style="width:1200px" src="../assets/imgs/jiagou.png" alt="">
      </div>
    </div>
    <div id="network" class="sale_network">
      <div class="title" style="margin-bottom: 40px; margin-top: 0">
        <img src="../assets/imgs/title_bg_left.png" alt="" />
        <span>销售网络</span>
        <img src="../assets/imgs/title_bg_right.png" alt="" />
      </div>
      <div class="sale_network_main">
        <div>
          <div class="sale_network_list">
            <div class="search_content">
              <div class="search_box">
                <svg-icon icon-class="search" />
                <input
                  class="inp"
                  type="text"
                  v-model="keyWord"
                  placeholder="请输入搜索内容"
                />
              </div>
              <div class="btn" @click="onSearch">搜索</div>
            </div>
            <ul class="list">
              <li class="list_item" v-for="item in saleList" :key="item.id">
                <div style="width: 38%">{{ item.channelName }}</div>
                <div style="width: 62%">
                  {{ item.administrationArea }}{{ item.address }}
                </div>
              </li>
            </ul>
          </div>
          <div class="page_turner">
            <!-- <button>22222</button> -->
            <button class="btn" @click="goPre" :disabled="pageNum == 1">上一页</button>
            <!-- <div class="btn"></div> -->
            <!-- <div class="btn">下一页</div> -->
            <button class="btn" @click="goNext" :disabled="pageNum == total">
              下一页
            </button>
            <div class="total">
              共<span>{{ total }}</span
              >页
            </div>
            <div class="jump">跳转</div>
            <input class="inp" v-model="pageNum" type="text" />
            <!-- <div class="btn go">GO</div> -->
            <button class="btn go" @click="jump">GO</button>
          </div>
        </div>
      </div>
    </div>
    <div id="contact" class="contact_us">
      <div class="title" style="margin-bottom: 40px; margin-top: 0">
        <img src="../assets/imgs/title_bg_left.png" alt="" />
        <span>联系我们</span>
        <img src="../assets/imgs/title_bg_right.png" alt="" />
      </div>
      <div class="contact_us_main">
        <div class="contact_us_content">
          <div class="contact_left">
            <div class="contact">
              <img src="../assets/introduction/phone-fill.png" alt="" />
              <div>联系电话：400-609-0999</div>
            </div>
            <div class="contact">
              <img src="../assets/introduction/mail-fill.png" alt="" />
              <div>联系邮箱：ljhnjyjt@163.com</div>
            </div>
            <div class="contact">
              <img src="../assets/introduction/map-pin-fill.png" alt="" />
              <div>公司地址：郑州市金水区北三环与中州大道交叉口融创中永中原大观11B30楼</div>
            </div>
          </div>
          <div id="allmap" class="contact_right"></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import $ from "jquery";
import Header from "../components/header";
import { getSaleList } from "../api/api";
import Footer from "../components/footer";

export default {
  name: "About",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      active: 0,
      saleList: [],
      pageNum: 1,
      keyWord: "",
      total: 0,
    };
  },
  mounted() {
    // new Swiper(".development-swiper", {
    //   loop: false,
    //   // 如果需要分页器
    //   pagination: ".swiper-pagination",
    //   slidesPerView: 1,
    //   // 如果需要前进后退按钮
    //   nextButton: ".swiper-button-next",
    //   prevButton: ".swiper-button-prev",
    //   // 如果需要滚动条
    //   // scrollbar: ".swiper-scrollbar",
    // });
    // 百度地图API功能
    var map = new BMap.Map("allmap"); // 创建Map实例
    map.centerAndZoom(new BMap.Point(113.705932,34.818453), 19); // 初始化地图,设置中心点坐标和地图级别
    //添加地图类型控件
    map.addControl(
      new BMap.MapTypeControl({
        mapTypes: [BMAP_NORMAL_MAP, BMAP_HYBRID_MAP],
      })
    );
    map.setCurrentCity("郑州"); // 设置地图显示的城市 此项是必须设置的
    map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放

    var local = new BMap.LocalSearch(map, {
      renderOptions: { map: map },
    });
    local.search("老家河南酒业集团有限公司");
    map.disableDragging();
    // $("table tbody tr:last").css({ display: "none" });
    this.getSaleList();
    var data = this.$route.query.data;
    console.log(data);
    if (data) {
      this.jumpA(data);
    }
  },

  methods: {
    goPre() {
      // console.log('goPre');
      this.pageNum--;
      console.log(this.pageNum);
      this.getSaleList();
    },
    goNext() {
      this.pageNum++;
      console.log(this.pageNum);
      this.getSaleList();
    },
    jumpA(data) {
      this.$nextTick(() => {
        setTimeout(() => {
          //获取指定的div盒
          let targetbox;
          if (data == 0) {
            targetbox = $("#groupIntroduction");
          } else if (data == 1) {
            targetbox = $("#network");
          } else if (data == 2) {
            targetbox = $("#contact");
          } else {
            console.log("targetbox");
          }

           var valOfScroll = targetbox.offset().top - 120;
          //让滚轴从当前位置的scroll在600毫秒内偏移到位置为valOfScroll。
          $("html,body").animate({ scrollTop: valOfScroll }, 600);
        });
      });
    },
    jump() {
      if (this.pageNum <= this.total) {
        this.getSaleList();
      }
    },
    onChange(index) {
      this.active = index;
    },
    onSearch() {
      // if (this.keyWord) {
      this.pageNum = 1;
      this.getSaleList();
      // }
    },
    getSaleList() {
      var data = {
        pageSize: 6,
        pageNum: this.pageNum,
        keyWord: this.keyWord,
      };
      getSaleList(data).then((res) => {
        console.log(res);
        if (res.code == 200 && res.data) {
          this.total = Math.ceil(res.data.total / 6);
          this.saleList = res.data.records;
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.about {
  .title {
    margin: 40px 0;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 70px;
      height: 26px;
    }
    span {
      font-size: 36px;
      color: #d6ac7d;
      margin: 0 10px;
    }
  }
  .introduce {
    img{
      width: 100%;
      height: auto;
    }
  }
  .sale_network {
    width: 100%;
    height: 810px;
    padding: 40px 0;
    background: url("../assets/introduction/cooperation_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center;
    .sale_network_main {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .sale_network_list {
      width: 572px;
      .search_content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .search_box {
          width: 450px;
          height: 40px;
          border: 1px solid #d3d3d3;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 15px;
          .svg-icon {
            width: 18px;
            height: 18px;
          }
          .inp {
            border: none;
            height: 20px;
            line-height: 20px;
            width: 390px;
            margin-left: 5px;
            background: none;
            outline: none;
            color: #333;
            font-size: 14px;
          }
          .inp::-webkit-input-placeholder {
            /* placeholder颜色  */
            color: #999999;
            /* placeholder字体大小  */
            font-size: 14px;
          }
        }
        .btn {
          width: 112px;
          height: 40px;
          background: #d6ac7d;
          text-align: center;
          line-height: 40px;
          cursor: pointer;
          font-size: 14px;
          color: #fff;
        }
      }
      .list {
        margin-top: 20px;
        border-top: 1px solid #eaeaea;
        height: 404px;
        .list_item {
          width: 100%;
          height: 64px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #eaeaea;
          padding: 0 15px;
          div {
            width: 50%;
            text-align: left;
            font-size: 16px;
            color: #666666;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  .contact_us {
    padding: 40px 0;
    .contact_us_content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .contact_right {
        width: 500px;
        height: 500px;
        margin-left: 20px;
      }
      .contact {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 24px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 14px;
        }
        div {
          font-size: 20px;
          color: #333333;
          text-align: left;
        }
      }
    }
  }
  .page_turner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 60px;
    .btn {
      width: 80px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #dbdbdb;
      font-size: 14px;
      color: #c8c8c8;
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        color: #d6ac7d;
      }
      &.go {
        width: 40px;
        margin-left: 10px;
      }
    }
    .total {
      color: #9e9e9e;
      font-size: 14px;
      margin-left: 10px;
      span {
        color: #d6ac7d;
        margin: 0 5px;
      }
    }
    .jump {
      color: #999999;
      font-size: 14px;
      margin: 10px;
    }
    .inp {
      width: 60px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #dbdbdb;
      outline: none;
      text-align: center;
      line-height: 40px;
      color: #d6ac7d;
    }
  }
}
</style>
