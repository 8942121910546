<template>
  <div class="custom">
    <Header></Header>
    <!-- <div class="banner img_content_pub">
      <img v-lazy="require('../assets/imgs/banner.png')" alt="" />
    </div> -->
    <div>
      <img
        style="width: 1200px"
        v-lazy="require('../assets/imgs/custom.jpg')"
        alt=""
      />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import Footer from "../components/footer";
import Header from "../components/header";

export default {
  name: "Custom",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      
    };
  },
  mounted() {
   
  },

  methods: {
   
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
