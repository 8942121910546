<template>
  <div class="media-center">
    <Header @link-jump2="jumpA"></Header>
    <div class="banner img_content_pub">
      <img v-lazy="require('../assets/imgs/banner.png')" alt="" />
    </div>
    <div class="media_content" id="media">
      <div class="tabs">
        <div class="tab" :class="active == 0 ? 'active' : ''" @click="onChange(0)">
          企业动态
        </div>
        <div class="tab" :class="active == 1 ? 'active' : ''" @click="onChange(1)">
          新闻报道
        </div>
      </div>
      <ul class="new_list">
        <li
          class="list_item"
          v-for="(item, index) in newsList"
          :key="item.id"
          @click="goDetail(item.id)"
        >
          <div
            class="img_content_pub item_img"
            @mouseenter="onNewsMouseenter(index)"
            @mouseleave="onNewsMouseleave(index)"
            :class="newsActive == index ? 'img_enlarge' : ''"
          >
            <img
              :class="newsLeave == index ? 'img_smaller' : ''"
              :src="item.picUrl"
              alt=""
            />
          </div>

          <div class="content">
            <div class="title">
              {{ item.title }}
            </div>
            <p class="text">
              {{ item.abstracts }}
            </p>
          </div>
          <img class="media_arrow" src="../assets/imgs/media_arrow.png" alt="" />
        </li>
        <!-- <li class="list_item">
          <div class="img_content_pub item_img">
            <img src="../assets/imgs/test.png" alt="" />
          </div>
          <div class="content">
            <div class="title">
              老家河南王孟：借超级IP文创优势，更多赋能“1亿人的待客酒”丨中酒展专访
            </div>
            <p class="text">
              4月26日下午，以“华夏之源，豫鉴中国”为主题的“老家河南”酒业战略合作暨豫鉴系列新品发布盛典在郑州举行，现场首次以著名IP“老家河南”作为独立产品品牌亮相的...
            </p>
          </div>
          <img class="media_arrow" src="../assets/imgs/media_arrow.png" alt="" />
        </li>
        <li class="list_item">
          <div class="img_content_pub item_img">
            <img src="../assets/imgs/test.png" alt="" />
          </div>
          <div class="content">
            <div class="title">
              老家河南王孟：借超级IP文创优势，更多赋能“1亿人的待客酒”丨中酒展专访
            </div>
            <p class="text">
              4月26日下午，以“华夏之源，豫鉴中国”为主题的“老家河南”酒业战略合作暨豫鉴系列新品发布盛典在郑州举行，现场首次以著名IP“老家河南”作为独立产品品牌亮相的...
            </p>
          </div>
          <img class="media_arrow" src="../assets/imgs/media_arrow.png" alt="" />
        </li> -->
        <div class="page_turner">
          <!-- <button>22222</button> -->
          <button class="btn" @click="goPre" :disabled="pageNum == 1">上一页</button>
          <!-- <div class="btn"></div> -->
          <!-- <div class="btn">下一页</div> -->
          <button class="btn" @click="goNext" :disabled="pageNum == total">下一页</button>
          <div class="total">
            共<span>{{ total }}</span
            >页
          </div>
          <div class="jump">跳转</div>
          <input class="inp" v-model="pageNum" type="text" />
          <!-- <div class="btn go">GO</div> -->
          <button class="btn go" @click="jump">GO</button>
        </div>
      </ul>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import $ from "jquery";
import Footer from "../components/footer";
import Header from "../components/header";
import { getNewsList } from "../api/api";

export default {
  name: "MediaCenter",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      active: 0,
      pageNum: 1,
      newsList: [],
      newsActive: -1,
      newsLeave: -1,
      total: 0,
    };
  },
  mounted() {
    // this.getNewsList();
    // new Swiper(".development-swiper", {
    //   loop: false,
    //   // 如果需要分页器
    //   pagination: ".swiper-pagination",
    //   slidesPerView: 1,
    //   // 如果需要前进后退按钮
    //   nextButton: ".swiper-button-next",
    //   prevButton: ".swiper-button-prev",
    //   // 如果需要滚动条
    //   // scrollbar: ".swiper-scrollbar",
    // });
    var data = this.$route.query.data;
    console.log(data);
    if (data) {
      if (data) {
        this.jumpA(data);
      }
    }
    else{
      this.getNewsList();
    }
  },
  // activated(){
  //   var data = this.$route.query.data;
  //   console.log(data);
  //   if (data) {
  //     this.jumpA(data);
  //   }
  // },
  // beforeRouteEnter(to, from, next) {
  //   to.meta.keepAlive = true;
  //   from.meta.keepAlive = false;
  //   next();
  // },
  beforeRouteLeave(to, from, next) {
    console.log(to);
    console.log(from);
    if (to.path == "/newsDetail") {
      // 设置下一个路由的 meta
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
      this.active = 0;
      this.pageNum = 1;
      this.newsActive = -1;
      this.newsLeave = -1;
      this.getNewsList();
    }
    to.meta.keepAlive = false;
    next();
  },
  methods: {
    jumpA(data) {
      this.$nextTick(() => {
        let targetbox = $("#media");
        // //获取这个div盒的高度位置
        var valOfScroll = targetbox.offset().top - 120;
          //让滚轴从当前位置的scroll在600毫秒内偏移到位置为valOfScroll。
          $("html,body").animate({ scrollTop: valOfScroll }, 600);
        this.active = Number(data);
        this.pageNum = 1;
        this.getNewsList();
      });
    },
    goDetail(id) {
      this.$router.push({
        path: "/newsDetail",
        query: { id: id, active: this.active },
      });
    },
    goPre() {
      // console.log('goPre');
      this.jumpTop();
      this.pageNum--;
      console.log(this.pageNum);
      this.getNewsList();
    },
    goNext() {
      this.jumpTop();
      this.pageNum++;
      console.log(this.pageNum);
      this.getNewsList();
    },
    jumpTop(data) {
      this.$nextTick(() => {
        setTimeout(() => {
          //获取指定的div盒
          let targetbox = document.getElementById("media");

          // //获取这个div盒的高度位置
          var target = targetbox.offsetTop - 120;
          // console.log(this.target);
          // //将滚动条定位到这个高度位置
          // document.documentElement.scrollTop = this.target;
          window.scrollTo({
            top: target,
            // behavior: "smooth",
          });
          // targetbox.scrollIntoView({
          //   behavior: "smooth", // 平滑过渡
          //   // block: "start", // 上边框与视窗顶部平齐。默认值
          // });
        });
      });
    },
    jump() {
      if (this.pageNum <= this.total) {
        this.getNewsList();
      }
    },
    onNewsMouseenter(index) {
      this.newsActive = index;
      // this.newsLeave = -1;
      if (this.newsLeave == index) {
        this.newsLeave = -1;
      }
    },
    onNewsMouseleave(index) {
      this.newsLeave = index;
      if (this.newsActive == index) {
        this.newsActive = -1;
      }
      // this.newsActive = -1;
    },
    getNewsList() {
      var data = {
        pageSize: 10,
        pageNum: this.pageNum,
        type: this.active + 1,
      };
      getNewsList(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          if (res.data) {
            this.total = Math.ceil(res.data.total / 10);
            this.newsList = res.data.records;
          }
        }
      });
    },
    onChange(index) {
      this.active = index;
      this.pageNum = 1;
      this.getNewsList();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.media-center {
  background: #f3f5f7;

  .media_content {
    width: 1200px;
    margin: 0 auto;
    padding: 60px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .tabs {
      width: 190px;
      margin-right: 20px;
      .tab {
        width: 190px;
        height: 54px;
        background: #ffffff;
        line-height: 54px;
        font-size: 20px;
        color: #333333;
        margin-bottom: 16px;
        cursor: pointer;
        &.active {
          background: #d6ac7d;
          color: #fff;
        }
        &:hover {
          background: #d6ac7d;
          color: #fff;
        }
      }
    }
    .new_list {
      width: 1000px;
      .list_item {
        width: 100%;
        height: 160px;
        background: rgba(255, 255, 255, 0.85);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        margin-bottom: 10px;
        cursor: pointer;
        .item_img {
          width: 250px;
          height: 140px;
        }
        img {
          object-fit: cover;
          width: 100%;
          height: auto;
        }
        .img_enlarge img {
          animation: enlarge 0.5s;
          -webkit-animation: enlarge 0.5s;
          animation-fill-mode: forwards;
        }
        img.img_smaller {
          animation: smaller 0.5s;
          -webkit-animation: smaller 0.5s;
          animation-fill-mode: forwards;
        }
        // img.main_img:hover {
        //   animation: enlarge 0.5s;
        //   -webkit-animation: enlarge 0.5s;
        // }
        @keyframes enlarge {
          from {
            transform: scale(1);
          }
          to {
            transform: scale(1.2);
          }
        }

        @-webkit-keyframes enlarge /* Safari 与 Chrome */ {
          from {
            transform: scale(1);
          }
          to {
            transform: scale(1.2);
          }
        }
        @keyframes smaller {
          from {
            transform: scale(1.2);
          }
          to {
            transform: scale(1);
          }
        }

        @-webkit-keyframes smaller /* Safari 与 Chrome */ {
          from {
            transform: scale(1.2);
          }
          to {
            transform: scale(1);
          }
        }
        .content {
          width: 574px;
          margin-left: 30px;
        }
        .title {
          width: 100%;
          height: 36px;
          font-size: 20px;
          color: #333333;
          margin-bottom: 10px;
          text-align: left;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;

          // margin-left: 30px;
        }
        .title:hover {
          color: #d6ac7d;
        }
        .text {
          width: 100%;
          height: 52px;
          font-size: 16px;
          color: #999999;
          line-height: 25px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: justify;
        }
        .media_arrow {
          width: 34px;
          height: 34px;
          margin-left: 40px;
        }
      }
    }
  }
  .page_turner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 60px;
    .btn {
      width: 80px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #dbdbdb;
      font-size: 14px;
      color: #c8c8c8;
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        color: #d6ac7d;
      }
      &.go {
        width: 40px;
        margin-left: 10px;
      }
    }
    .total {
      color: #9e9e9e;
      font-size: 14px;
      margin-left: 10px;
      span {
        color: #d6ac7d;
        margin: 0 5px;
      }
    }
    .jump {
      color: #999999;
      font-size: 14px;
      margin: 10px;
    }
    .inp {
      width: 60px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #dbdbdb;
      outline: none;
      text-align: center;
      line-height: 40px;
      color: #d6ac7d;
    }
  }
}
</style>
