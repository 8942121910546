<template>
  <div class="news-detail">
    <Header></Header>
    <div class="banner img_content_pub">
      <img v-lazy="require('../assets/imgs/banner.png')" alt="" />
    </div>
    <div class="title">媒体中心>企业动态>详情</div>
    <div class="detail_content">
      <h2 class="detail_title">{{detail.title}}</h2>
      <div class="sub_title">
        <span class="date">发布日期：{{detail.createTime}}</span>
        <span>来源：{{detail.source}}</span>
      </div>
      <div class="main" v-html="detail.content"></div>
      <div class="line"></div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import Footer from "../components/footer";
import Header from "../components/header";
import { getNewDetail } from "../api/api";

export default {
  name: "NewsDetail",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      id: null,
      active: 0,
      detail: {}
    };
  },
  mounted() {
    // new Swiper(".development-swiper", {
    //   loop: false,
    //   // 如果需要分页器
    //   pagination: ".swiper-pagination",
    //   slidesPerView: 1,
    //   // 如果需要前进后退按钮
    //   nextButton: ".swiper-button-next",
    //   prevButton: ".swiper-button-prev",
    //   // 如果需要滚动条
    //   // scrollbar: ".swiper-scrollbar",
    // })
    
    console.log(this.$route);
    this.id = this.$route.query.id;
    this.active = this.$route.query.active;
    this.getNewDetail();
  },
  beforeRouteEnter (to, from, next) {
    // ...
    console.log(to);
    console.log(from);
    // from.meta.keepAlive = true;
    // from.meta.keepAlive = false;
    next();
  },

  methods: {
    getNewDetail(){
      var data = {
        id: this.id
      }
      getNewDetail(data).then((res) => {
        console.log(res);
        if(res.code == 200){
          this.detail = res.data;
        }
      })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.news-detail {
  background: #f3f5f7;

  .title {
    width: 1200px;
    margin: 0 auto;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    color: #9e9e9e;
    text-align: left;
  }
  .detail_content {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 50px;
    background: #fff;
    .detail_title {
      text-align: center;
      font-size: 20px;
      color: #333333;
      line-height: 28px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .sub_title {
      font-size: 14px;
      color: #999999;
      .date {
        margin-right: 28px;
      }
      padding-bottom: 30px;
      border-bottom: 1px solid #d8d8d8;
    }
    .line {
      width: 100%;
      height: 1px;
      background: #d8d8d8;
      margin: 40px 0;
    }
    
  }
  .main{
      margin-top: 40px;
      text-align: justify;
      /deep/ img{
        display: block;
        width: 600px;
        height: auto;
        margin: 10px auto;
      }
      // /deep/ div{
      //   font-size: 16px;
      //   color: #333;
      //   font-family: Source Han Sans CN-Regular;
      //   line-height: 36px;
      // }
      // /deep/ p{
      //   font-size: 16px;
      //   color: #333;
      //   font-family: Source Han Sans CN-Regular;
      //   line-height: 36px;
      // }
      // /deep/ span{
      //   font-size: 16px;
      //   color: #333;
      //   font-family: Source Han Sans CN-Regular;
      //   line-height: 36px;
      // }
    }
}
</style>
