<template>
  <div class="hometown-gift">
    <Header @link-jump1="jump" @ie-jump="ieJump"></Header>
    <div class="banner img_content_pub">
      <img v-lazy="require('../assets/imgs/banner.png')" alt="" />
    </div>
    <div
      class="gift_content"
      v-for="(item, index) in productList"
      :class="'gift_content_' + index"
      :key="item.label"
    >
      <div class="title">
        <img src="../assets/imgs/title_bg_left.png" alt="" />
        <span>{{ item.label }}</span>
        <img src="../assets/imgs/title_bg_right.png" alt="" />
      </div>
      <ul class="gift_list">
        <li class="list_item" v-for="dataItem in item.data" :key="dataItem.id">
          <div class="gift_img img_content_pub">
            <img v-lazy="dataItem.proPicture" alt="" />
          </div>
          <div class="gift_title">{{ dataItem.proName }}</div>
          <div class="gift_detail">
            酒精度: {{ dataItem.alcoholDegree }}%vol&nbsp;&nbsp;&nbsp;净含量:
            {{ dataItem.proSpecs }}ml
          </div>
          <div class="gift_detail gift_detail_1">
            {{ dataItem.proBriefIntroduction }}
          </div>
          <a v-if="item.label == '定制系列'" target="_blank" href="/custom" class="btn">MORE ></a>
          <a v-else target="_blank" :href="dataItem.tmallUrl" class="btn">点击购买 ></a>
        </li>
      </ul>
    </div>
    <!-- <div class="gift_content" style="padding-top: 0">
      <div class="title">
        <img src="../assets/imgs/title_bg_left.png" alt="" />
        <span>豫品系列</span>
        <img src="../assets/imgs/title_bg_right.png" alt="" />
      </div>
      <ul class="gift_list">
        <li class="list_item" v-for="(item, index) in ypList">
          <div class="gift_img img_content_pub">
            <img v-lazy="item.proPicture" alt="" />
          </div>
          <div class="gift_title">{{ item.proName }}</div>
          <div class="gift_detail">
            酒精度: {{ item.alcoholDegree }}%vol&nbsp;&nbsp;&nbsp;净含量:
            {{ item.proSpecs }}ml
          </div>
          <a :href="item.tmallUrl" class="btn">点击购买<svg-icon icon-class="enter" /></a>
        </li>
      </ul>
    </div>
    <div class="gift_content" style="padding-top: 0">
      <div class="title">
        <img src="../assets/imgs/title_bg_left.png" alt="" />
        <span>收藏系列</span>
        <img src="../assets/imgs/title_bg_right.png" alt="" />
      </div>
      <ul class="gift_list">
        <li class="list_item" v-for="(item, index) in scList">
          <div class="gift_img img_content_pub">
            <img v-lazy="item.proPicture" alt="" />
          </div>
          <div class="gift_title">{{ item.proName }}</div>
          <div class="gift_detail">
            酒精度: {{ item.alcoholDegree }}%vol&nbsp;&nbsp;&nbsp;净含量:
            {{ item.proSpecs }}ml
          </div>
          <a :href="item.tmallUrl" class="btn">点击购买<svg-icon icon-class="enter" /></a>
        </li>
      </ul>
    </div>
    <div class="gift_content" style="padding-top: 0">
      <div class="title">
        <img src="../assets/imgs/title_bg_left.png" alt="" />
        <span>定制系列</span>
        <img src="../assets/imgs/title_bg_right.png" alt="" />
      </div>
      <ul class="gift_list">
        <li class="list_item" v-for="item in dzList">
          <div class="gift_img img_content_pub">
            <img v-lazy="item.proPicture" alt="" />
          </div>
          <div class="gift_title">{{ item.proName }}</div>
          <div class="gift_detail">
            酒精度: {{ item.alcoholDegree }}%vol&nbsp;&nbsp;&nbsp;净含量:
            {{ item.proSpecs }}ml
          </div>
          <div class="btn">MORE<svg-icon icon-class="enter" /></div>
        </li>
      </ul>
    </div> -->
    <Footer></Footer>
  </div>
</template>

<script>
//页面引入swiper
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import $ from "jquery";
import Footer from "../components/footer";
import Header from "../components/header";
import { getGroupProducts, getSeriesList } from "../api/api";

export default {
  name: "HometownGift",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      developmentList: [
        {
          url: require("../assets/imgs/test.png"),
          title: "2009年：记忆中原 老家河南 宣传片",
          content:
            "5月在中央电视台播出，不仅对河南的旅游资源起到了很好的宣传推介作用，而且传达出中原河南作为中华文化之源厚重的亲情气息。",
        },
        {
          url: require("../assets/imgs/test.png"),
          title: "2009年：记忆中原 老家河南 宣传片222222",
          content:
            "5月在中央电视台播出，不仅对河南的旅游资源起到了很好的宣传推介作用，而且传达出中原河南作为中华文化之源厚重的亲情气息。",
        },
      ],
      yjList: [],
      ypList: [],
      scList: [],
      dzList: [],
      seriesList: [],
      productList: [],
    };
  },
  mounted() {
    // new Swiper(".development-swiper", {
    //   loop: false,
    //   // 如果需要分页器
    //   pagination: ".swiper-pagination",
    //   slidesPerView: 1,
    //   // 如果需要前进后退按钮
    //   nextButton: ".swiper-button-next",
    //   prevButton: ".swiper-button-prev",
    //   // 如果需要滚动条
    //   // scrollbar: ".swiper-scrollbar",
    // });
    // this.getGroupProducts();
    this.getSeriesList();
  },

  methods: {
    jump(data) {
      this.$nextTick(() => {
        setTimeout(() => {
          // if (!window.scrollTo()) {
          //   this.ieJump(data);
          //   return;
          // }
          // let targetbox = document.getElementsByClassName("gift_content_" + data);
          // //获取指定的div盒
          // console.log(targetbox);

          // // //获取这个div盒的高度位置
          // var target = targetbox[0].offsetTop - 120;
          // console.log(target);
          // window.scrollTo({
          //   top: target,
          //   behavior: "smooth",
          // });
          // $(".gift_content_" + data).animate({
          //   top: target
          // },500)
          var valOfScroll = $(".gift_content_" + data).offset().top - 120;
          //让滚轴从当前位置的scroll在600毫秒内偏移到位置为valOfScroll。
          $("html,body").animate({ scrollTop: valOfScroll }, 600);
        });
      });
    },
    ieJump(data) {
      this.$nextTick(() => {
        setTimeout(() => {
          //获取指定的div盒
          let targetbox;
          var target;
          if (data > 0) {
            targetbox = document.getElementsByClassName("gift_content_" + (data - 1));
            target = targetbox[0].offsetHeight;
          } else {
            targetbox = document.getElementsByClassName("gift_content_" + data);
            target = targetbox[0].offsetTop - 120;
          }
          console.log(target);
          window.scrollBy(0, target);
        });
      });
    },
    getSeriesList() {
      getSeriesList().then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.seriesList = res.data;
          this.getGroupProducts();
        }
      });
    },
    getGroupProducts() {
      getGroupProducts().then((res) => {
        console.log(res);
        if (res.code == 200) {
          var data = res.data;
          console.log(data["豫鉴系列"]);
          for (var i = 0; i < this.seriesList.length; i++) {
            for (var key in data) {
              if (key == this.seriesList[i].label) {
                console.log(key);
                this.productList.push({
                  label: key,
                  data: data[key],
                });
              }
            }
          }
          console.log(this.productList);
          var data = this.$route.query.data;
          console.log(data);
          if (data) {
            this.jump(data);
          }
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.hometown-gift {
  background: #f3f5f7;
  .gift_content {
    padding-top: 40px;
    .title {
      margin-bottom: 40px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 70px;
        height: 26px;
      }
      span {
        font-size: 36px;
        color: #d6ac7d;
        margin: 0 10px;
      }
    }
    .gift_list {
      width: 1200px;
      margin: 0 auto;
      // text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .list_item {
        width: 270px;
        background: #ffffff;
        padding: 15px;
        text-align: center;
        margin-right: 40px;
        margin-bottom: 40px;
        padding-bottom: 30px;
        // flex-shrink: 0;
        // flex-grow:0;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-last-child(1) {
          margin-right: 0;
        }

        // flex: 1;
        .gift_img {
          width: 100%;
          height: 290px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .gift_title {
          font-size: 20px;
          color: #d6ac7d;
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .gift_detail {
          font-size: 14px;
          color: #666666;
        }
        .gift_detail_1 {
          font-size: 12px;
          color: #999999;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 20px;
          margin-top: 10px;
        }
        .btn {
          color: #d6ac7d;
          width: 130px;
          height: 40px;
          background: #ffffff;
          border-radius: none;
          border: 1px solid #d6ac7d;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            background: #d6ac7d;
            color: #fff;
            /deep/ .svg-icon {
              fill: #ffffff !important;
            }
          }
          .svg-icon {
            width: 18px;
            height: 18px;
            fill: #ffffff !important;
          }
          margin: 0 auto;
          margin-top: 20px;
        }
      }
      .list_item.mr {
        margin-right: 40px;
      }
    }
  }
}
</style>
